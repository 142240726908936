  <template>

  <travio-center-container grid-width='1/2'>
    <h2 class="mb-4" style="color:#636363">{{formTitle}}</h2>
    <vx-card>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Location Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.locationName" v-validate="'required|max:100'" name="Location Name" />
          <span class="text-danger text-sm">{{ errors.first('Location Name') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Search Location*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-button @click="showLocationAutoComplete=true" class="w-full" color="primary">Search Location</vs-button>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Minimum Star Rating*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select 
            v-model="form.minimumRating" 
            :reduce="(option) => option.code" 
            name="Minimum Star Rating" 
            :options="ratingOptions" :clearable="false"
            v-validate="'required'" />
            <span class="text-danger text-sm">{{ errors.first('Minimum Star Rating') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Is Active</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-switch
            class=""
            v-model="form.isActive"
          />
        </div>
      </div>

      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button class="" color="danger" @click="onCancel" type="filled">Cancel</vs-button>      
        <vs-button class="ml-2" color="primary" @click="onSave" type="filled">Save</vs-button>      
      </div>


    </vx-card>
    <location-editor-auto-complete 
      v-if="showLocationAutoComplete"
      :applicationId="applicationId"
      :location="locationStateForAutoComplete"
      :noSave="true"
      :geographyType="2"
      @onEditSuccess="onEditSuccessAutoComplete"
      @onCancel="showLocationAutoComplete=false" 
    />

  </travio-center-container>
</template>

<script>
import { Form } from '@/core-modules/form-framework/Form.js'
import LocationEditorAutoComplete from '../location-lists/components/LocationEditorAutoComplete.vue'

export default {
  components: {
    LocationEditorAutoComplete,
  },
  props: {
    applicationId: { required: true },
    locationId: { required: false, default: 0 },
  },
  data () {
    return {
      form: new Form({
        id: 0,
        locationName: '',
        locationGeoId: null,
        minimumRating: 3,
        locationState: null,
        isActive: true,
      }),
      ratingOptions: [
        {label: '3.0', code: 3.0},
        {label: '3.5', code: 3.5},
        {label: '4.5', code: 4.5},
        {label: '5.0', code: 5.0},
      ],
      showLocationAutoComplete: false,
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    formTitle() {
      return this.locationId == 0 ? 'Add Skyscanner Location' : 'Edit Skyscanner Location'
    },
    locationStateForAutoComplete () {
      let jsonData = null
      if(this.locationId) {
        jsonData = JSON.stringify({
          id: this.form.locationGeoId,
          name: this.form.locationName
        })
      }
      return { jsonData: jsonData}
    },
  },
  async created () {

    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }
    
    
    
    try {
      if (this.locationId) {
        // Edit
        this.$vs.loading()
        const resp = await this.$http.get(`api/skyscannerLocations/apps/${this.applicationId}/${this.locationId}`)
        this.form = Object.assign(this.form, resp.data)
        
      }

    } catch (error) {
      this.$_notifyFailure(error)
    } finally {
      this.$vs.loading.close()
    }
    
  },
  mounted() {

  },
  methods: {
    async onSave (){

      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }
      this.$vs.loading()
      if (!this.locationId) {
        try {
          await this.$http.post(`api/skyscannerLocations/apps/${this.applicationId}`, this.form.data())
          this.$_notifySuccess('Successfully added Skyscanner Location');
        } catch (error) {
          this.$_notifyFailure(error)
        }
      } else {
        try {
          await this.$http.put(`api/skyscannerLocations/apps/${this.applicationId}`, this.form.data())
          this.$_notifySuccess('Successfully updated Skyscanner Location');
        } catch (error) {
          this.$_notifyFailure(error)
        }
      }
      
      this.$vs.loading.close();
      this.onCancel()
    },
    onCancel() {
      this.$router.push({ name: 'application-tools-skyscanner-locations', 
        params: { 
          applicationId: this.applicationId,
        }
      })
    },
    onEditSuccessAutoComplete (locationState) {
      this.form.locationState = locationState
      const locationStateObj = JSON.parse(locationState)
      this.form.locationGeoId = locationStateObj && locationStateObj.id
      this.form.locationName = locationStateObj && locationStateObj.name
      this.showLocationAutoComplete = false
    },
    
  }
}
</script>

<style>

</style>
